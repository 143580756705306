<template>
  <section class="dtc-main-section mt-2">
    <nav class="dtc-nav mb-2">
      <b-input-group prepend="109 學年度原住民族及離島地區醫事人員養成計畫各籍屬各學校預計培育人數一覽表" class="mt-3">
        <b-button variant="warning">醫學系</b-button>
        <b-button variant="info">牙醫學系</b-button>
        <b-button variant="danger">其他學系</b-button>
      </b-input-group>
    </nav>
    <!-- <div class="dtc-nav">
      <b-button variant="warning">醫學系</b-button>
      <b-button variant="info">牙醫學系</b-button>
      <b-button variant="danger">其他學系</b-button>
    </div>-->
    <DocForm></DocForm>
  </section>
</template>

<script>
import queryString from "query-string";
import DocForm from "./Apply5-1";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";
const titles = [
  "",
  "原住民籍",
  "澎湖縣",
  "金門縣",
  "連江縣",
  "綠島籍",
  "琉球籍",
  "蘭嶼籍",
  "偏鄉籍",
  "總計"
];
const schools = new Array(5).fill("國立臺灣大學 (北)");

export default {
  name: "totalSpend5",
  data() {
    return { titles, schools, v: "" };
  },
  components: {
    DocForm
  },

  methods: {
    doctorRowUpdate(item) {
      //update
    }
  },
  async mounted() {},
  beforeMount() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  width: 1280px;
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.h1-dtc,
.dtc-main {
  display: grid;
  position: relative;
  grid-template-columns: 1fr repeat(9, 120px);
  border: 1px solid black;
  // background: var(--gray);
  > div {
    position: relative;
    height: 60px;
    font-weight: bold;
    line-height: 60px;
    font-size: 1.3rem;
    border-right: 1px solid black;
    //color: white;
  }
  > div:last-child {
    border-right: none;
  }
  > div:first-child::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    display: block;
    width: 100%;
    height: 1px;
    background: black;
    transform: rotate(13deg);
  }
}
.h1-dtc::after,
.h1-dtc::before {
  content: attr(data-msg1);
  position: absolute;
  font-size: 16px;
  top: 5px;
  left: 140px;
}

.h1-dtc::after {
  content: attr(data-msg2);
  top: 30px;
  left: 10px;
}
.dtc-main {
  border-top: none;
  > div {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
  > div:first-child::after {
    opacity: 0;
  }
}
.dtc-nav {
  // background: var(--primary);
  display: grid;
  border-radius: 5px;
  grid-template-columns: max-content;
  //justify-content: space-around;
  padding-top: 10px;
}
</style>
